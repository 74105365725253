import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import EstimationForm from '../components/EstimationForm';

export const EstimatePageTemplate = ({
  hero,
  basic,
  subject,
  info,
  submitLabel,
  aftertext
}) => (
  <div className="Estimate">
    <div className="Estimate__content">
      <h1 className="Estimate__title">{hero.title}</h1>
      <p>{hero.text}</p>

      <EstimationForm {...{ basic, subject, info, submitLabel, aftertext }} />
    </div>
  </div>
);

const EstimatePage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout variants={['backgrounded']} showContact={false} meta={frontmatter.meta}>
      <EstimatePageTemplate {...frontmatter} />
    </Layout>
  );
};

EstimatePage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default EstimatePage;

export const estimatePageQuery = graphql`
  query EstimatePageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "estimate-page" } }) {
      frontmatter {
        meta {
          title
          description
          image {
            publicURL
            name
          }
        }

        hero {
          title
          text
        }

        basic {
          heading
          nameLabel
          emailLabel
          companyLabel
        }

        subject {
          heading
          options {
            name
            label
            text
            icon {
              publicURL
              name
            }
          }
        }

        info {
          heading
          budgetLabel
          startLabel
        }
        submitLabel
        aftertext
      }
    }
  }
`;

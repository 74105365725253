import React from 'react';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import classNames from 'classnames';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

import WrappedField from './WrappedField';
import Button from './Button';
import Form from './Form';
import StatementContact from './StatementContact';

import { encode } from '../helpers/encode';
import IconedText from './IconedText';

const formSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  email: Yup.string()
    .email('This is not a valid email')
    .required('Email is required'),
  company: Yup.string().required('Company name is required'),
  subject: Yup.string().required('Choose an option'),
  found: Yup.string(),
  message: Yup.string().required('Message is required'),
  start: Yup.string().required('Start date is required'),
});

const initialValues = {
  name: '',
  email: '',
  company: '',
  subject: '',
  message: '',
  found: '',
  start: '',
};

class RadioButton extends React.Component {
  onKeydown = e => {
    const {
      form: { setFieldValue },
      field: { name },
      option,
    } = this.props;
    if (e.key === 'Enter') {
      setFieldValue(name, option.name);
    }
  };

  onFocus = () => {
    window.addEventListener('keydown', this.onKeydown);
  };

  onBlur = () => {
    window.removeEventListener('keydown', this.onKeydown);
  };

  render() {
    const {
      form: { errors, touched },
      field: { name, value, onChange },
      option,
      text,
      ...props
    } = this.props;
    const wrapperClasses = classNames([
      'EstimationForm__option',
      { 'EstimationForm__option--selected': option.name === value },
      { 'EstimationForm__option--invalid': touched[name] && errors[name] },
    ]);

    return (
      <label
        className={wrapperClasses}
        tabIndex="0"
        onFocus={this.onFocus}
        onBlur={this.onBlur}
        htmlFor={option.name}
      >
        <input
          name={name}
          id={option.name}
          type="radio"
          value={option.name} // could be something else for output?
          checked={option.name === value}
          onChange={onChange}
          className="EstimationForm__option-radio"
          {...props}
        />

        <IconedText
          tagName="span"
          iconSrc={option.icon && option.icon.publicURL}
          title={`${option.label}`}
          htmlFor={option.name}
          variants={['center']}
        />
        {option.text}
      </label>
    );
  }
}

class EstimationForm extends React.Component {
  constructor(props) {
    super(props);

    this.idHTML = 'estimationForm';
    this.netlifyFormName = 'estimationForm';
    this.netlifyHoneypotName = 'tricky-field';
    this.fieldNames = [
      'name',
      'email',
      'company',
      'subject',
      'message',
      'start',
      'found'
    ];

    this.state = {
      hasSubmitSucceeded: false,
      hasSubmitFailed: false,
    };
  }

  get isNetlifyFormActive() {
    return process.env.GATSBY_IS_NETLIFY_FORM_ACTIVE === 'TRUE';
  }

  prepareData = values => {
    const { fieldNames, isNetlifyFormActive } = this;

    const netlifyData = isNetlifyFormActive
      ? { 'form-name': this.netlifyFormName }
      : {};

    if (isNetlifyFormActive) {
      fieldNames.push(this.netlifyHoneypotName);
    }

    return fieldNames.reduce((acc, fieldName) => {
      const val =
        typeof values[fieldName] === 'object'
          ? values[fieldName].value
          : values[fieldName];
      acc[fieldName] = val || '';
      return acc;
    }, netlifyData);
  };

  sendForm = async data => {
    const res = await fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode(data),
    });
    const newState = res.ok
      ? { hasSubmitSucceeded: true }
      : { hasSubmitFailed: true };

    this.setState(newState);
  };

  onSubmit = values => {
    const data = this.prepareData(values);
    this.sendForm(data);
  };

  render() {
    const { hasSubmitSucceeded, hasSubmitFailed } = this.state;
    const { basic, subject, info, submitLabel, aftertext } = this.props;

    const netlifyProps = this.isNetlifyFormActive
      ? {
          name: this.netlifyFormName,
          'data-netlify': true,
          'data-netlify-honeypot': this.netlifyHoneypotName,
        }
      : {};

    let submit = (
      <Button type="submit" className="Form__submit" variants={['wide']}>
        {submitLabel}
      </Button>
    );
    if (hasSubmitSucceeded) {
      submit = (
        <p className="Form__submit">
          Thanks for reaching out! We will get back to you shortly.
        </p>
      );
    } else if (hasSubmitFailed) {
      submit = (
        <p className="Form__submit">
          Your form was <strong>not</strong> saved. Please reach us at{' '}
          <strong>hello@getindata.com</strong>. Thanks!
        </p>
      );
    }

    return (
      <Formik
        validationSchema={formSchema}
        onSubmit={this.onSubmit}
        initialValues={initialValues}
      >
        {({ errors, touched, setFieldValue, setFieldTouched, values }) => (
          <Form {...netlifyProps} className="EstimationForm" id={this.idHTML}>
            {this.isNetlifyFormActive && (
              <input type="hidden" name={this.netlifyHoneypotName} />
            )}
            {this.isNetlifyFormActive && (
              <input
                type="hidden"
                name="form-name"
                value={this.netlifyFormName}
              />
            )}

            <input
              type="hidden"
              name="idHTML"
              value={this.idHTML}
            />

            <fieldset className="EstimationForm__step">
              <h2 className="EstimationForm__step-title">
                <span className="EstimationForm__step-prefix">Step 01</span>
                {basic.heading}
              </h2>

              <div className="Form__level">
                <WrappedField
                  name="name"
                  label={basic.nameLabel}
                  type="text"
                  error={errors.name}
                  isTouched={touched.name}
                  placeholder="Type your name"
                />
              </div>

              <div className="Form__level">
                <WrappedField
                  name="email"
                  label={basic.emailLabel}
                  type="email"
                  error={errors.email}
                  isTouched={touched.email}
                  placeholder="Type your email"
                />
              </div>

              <div className="Form__level">
                <WrappedField
                  name="company"
                  label={basic.companyLabel}
                  type="text"
                  error={errors.company}
                  isTouched={touched.company}
                  placeholder="Type your company"
                />
              </div>

              <div className="Form__level">
                <WrappedField
                  name="found"
                  label="How did you find us?"
                  type="text"
                  error={errors.name}
                  isTouched={touched.name}
                  placeholder="Type your answer"
                />
              </div>
            </fieldset>

            <fieldset className="EstimationForm__step">
              <h2 className="EstimationForm__step-title">
                <span className="EstimationForm__step-prefix">Step 02</span>
                {subject.heading}
              </h2>

              <div className="Form__level EstimationForm__options">
                {subject.options.map((option, i) => (
                  <Field
                    key={i}
                    name="subject"
                    component={RadioButton}
                    option={option}
                  />
                ))}
              </div>
            </fieldset>

            <fieldset className="EstimationForm__step">
              <h2 className="EstimationForm__step-title">
                <span className="EstimationForm__step-prefix">Step 03</span>
                {info.heading}
              </h2>

              <ReactMarkdown 
                className="EstimationForm__text" 
                children={aftertext} 
                rehypePlugins={[rehypeRaw]} 
              />

              <div className="Form__level">
                <WrappedField
                  name="start"
                  label={info.startLabel}
                  type="text"
                  error={errors.start}
                  isTouched={touched.start}
                  placeholder="dd - mm - yyyy"
                />
              </div>

              <div className="Form__level">
                <WrappedField
                  name="message"
                  label={info.budgetLabel}
                  type="text"
                  error={errors.message}
                  isTouched={touched.message}
                  component="textarea"
                />
              </div>
              
              <div className="Form__level">
                <WrappedField
                  name="newsletter"
                  label="I want to receive news notifications (including marketing information) in the form of a newsletter to my email address."
                  error={errors.newsletter}
                  isTouched={touched.newsletter}
                  type="checkbox"
                  isCheckbox={true}
                />
              </div>
            </fieldset>

            <div className="EstimationForm__statement">
              <StatementContact />
            </div>

            {submit}
          </Form>
        )}
      </Formik>
    );
  }
}

export default EstimationForm;
